import { AiOutlineCloudUpload } from "react-icons/ai";
import { useState, useRef, useEffect, memo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth0, User } from "@auth0/auth0-react";
import { useOutletContext } from "react-router-dom";
import FetchDocuments from "../assets/FetchDocuments";
import { CgSoftwareUpload } from "react-icons/cg";
import Questions from "../components/Single_Components/Questions";
import OptinalFields from "../components/Single_Components/OptionalFields";
import Disclaimer from "../components/Single_Components/Disclaimer";
import { HiOutlineDocumentText } from "react-icons/hi";
import Container from "../components/Single_Components/Container";
import FetchUserInfo from "../assets/FetchBalance";
import Notification from "../components/Single_Components/Notification";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { notifications } from "../components/layout/MainLayout";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Modal from "../components/Single_Components/ModalUpload";
import { AiOutlineLink } from "react-icons/ai";
import { MdOutlineError } from "react-icons/md";
import Onboarding from "../components/Single_Components/Onboarding";
import Dropdown from "../components/Single_Components/Dropdown";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { playbook } from "../components/layout/MainLayout";
import InfoBox from "../components/layout/infoBox";

function UploadContract({ setTimeOfJob }) {
  const [file, setFile] = useState();
  const [file2, setFile2] = useState();
  const [searchedjobs, setSearchedJobs] = useOutletContext();
  const [upload_text, setUploadText] = useState(
    <>
      Drag & drop or{" "}
      <span className="underline underline-offset-2">Select file</span>
    </>
  );
  const [upload_text2, setUploadText2] = useState("Drag & drop or Select file");

  const [questions, setQuestions] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState("");
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [dragActive, setDragActive] = useState(false);

  const [option, setDropDownChild] = useState(Object.values(playbook)[0]);
  const [shouldRedact, setShouldRedact] = useState(false);

  const [country, setCountry] = useState();
  const [contractType, setContractType] = useState();
  const [partySide, setPartySide] = useState();
  const [purpose, setPurpose] = useState();

  function checkboxHandler() {
    setShouldRedact(!shouldRedact);
  }
  const isRedactCheckboxVisible = useFeatureFlagEnabled(
    "[FE]-[Upload]-make_redact_checkbox_visible"
  );
  const CheckBoxComponent = memo(function RedactCheckbox() {
    if (
      !isRedactCheckboxVisible ||
      tabsData[activeTabIndex].value === "two_documents"
    ) {
      return null;
    }
    return (
      <div className="relative flex items-start ml-10 mt-10">
        <div className="pl-5 mb-10">
          <div className="flex items-center">
            <input
              id="redact"
              aria-describedby="redact-choice"
              name="redact"
              type="checkbox"
              style={{ accentColor: "#748AF2" }}
              onChange={checkboxHandler}
              checked={shouldRedact}
              className="h-5 w-5 rounded border-[#748AF2] text-[#748AF2] focus:ring-[#748AF2] mr-8"
            />
            <label htmlFor="comments" className="font-light text-sm">
              Redact PII information
            </label>
          </div>
          <p className="font-light text-xs mt-1">
            <span className="text-[#747775]">
              Redacts common Pll data (ex emails) but not all Pll.
            </span>{" "}
            <br />{" "}
            <span className="font-bold text-[#747775]">
              Contact us if you need other data to be redacted.
            </span>
          </p>
        </div>
      </div>
    );
  });
  const handleUrl = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    setUrl(pastedText);
  };
  const handleClearFile = () => {
    setFile(null);
    setUploadText("Drag & drop or browse");
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFile(e.dataTransfer.files[0]);
    if (e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      setUploadText(e.dataTransfer.files[0].name);
    }
    setDragActive(false);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setUploadText(e.target.files[0].name);
    }
  };
  const handleFileChange2 = (e) => {
    if (e.target.files) {
      setFile2(e.target.files[0]);
      setUploadText2(e.target.files[0].name);
    }
  };

  const [notification_component, setNotification_component] = useState(null);
  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const optionalFieldsEnabled = useFeatureFlagEnabled(
    "[FE]-[Upload]-optional_fields_visible"
  );

  const handleUploadClickFile = async () => {
    setUploading(true);
    setError(null);
    try {
      // create FormData to send the file
      const formData = new FormData();

      formData.append("email_text", questions.join("\n"));
      formData.append("source", user.email);
      // obtained from playbook dropdown option
      formData.append("playbook_id", option);
      formData.append("should_redact", shouldRedact);

      if (file && (file.name.includes(".docx") || file.name.includes(".pdf"))) {
        formData.append("job_type", "review");
        formData.append("original_file", file, file.name);
      } else if (!file) {
        throw new Error("No file selected");
      } else if (!(file.name.includes(".docx") || file.name.includes(".pdf"))) {
        throw new Error(
          "File not supported, supported files are (.pdf, .docx)"
        );
      }

      if (optionalFieldsEnabled) {
        if (country && country !== "") {
          formData.append("country", country);
        }
        if (contractType && contractType !== "") {
          formData.append("contract_type", contractType);
        }
        if (partySide && partySide !== "") {
          formData.append("party_side", partySide);
        }
        if (purpose && purpose !== "") {
          formData.append("purpose", purpose);
        }
      }
      // formData.append('url', url);
      const requestOptions = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        // mode: "no-cors"
      };
      window.dataLayer.push({ event: "review_document_button_pressed" });
      // Perform the upload using axios or fetch
      const res = await axios.post(
        process.env.REACT_APP_DOMAIN + "/api/v1/jobs/",
        formData,
        requestOptions
      );

      FetchDocuments(
        [searchedjobs, setSearchedJobs],
        localStorage.getItem("token")
      );
      localStorage.setItem("newJobCreatedDate", new Date());
      openModal();
      setUploading(false);
      setFile(null);
      setUploadText(
        <>
          Drag & drop or{" "}
          <span className="underline underline-offset-2">Select file</span>
        </>
      );
    } catch (err) {
      console.log(err.message);
      setUploading(false);
      notifications.push({
        icon: <AiOutlineCloseCircle size={25} color="red" />,
        upper_text: "Unable to review your document!",
        lower_text: err.message,
      });
      setNotification_component(() => <Notification />);
      // setNotification_component(null);
    }
  };

  const handleUploadClickURL = async () => {
    setUploading(true);
    setError(null);
    try {
      // create FormData to send the file
      const formData = new FormData();

      formData.append("email_text", questions.join("\n"));
      formData.append("source", user.email);
      formData.append("should_redact", shouldRedact);
      if (url) {
        // obtained from playbook dropdown option
        formData.append("playbook_id", option);

        formData.append("job_type", "review");
        formData.append("url", url);
      } else {
        throw new Error("Please insert a valid URL.");
      }

      if (optionalFieldsEnabled) {
        if (country && country !== "") {
          formData.append("country", country);
        }
        if (contractType && contractType !== "") {
          formData.append("contract_type", contractType);
        }
        if (partySide && partySide !== "") {
          formData.append("party_side", partySide);
        }
        if (purpose && purpose !== "") {
          formData.append("purpose", purpose);
        }
      }
      // formData.append('url', url);
      const requestOptions = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        // mode: "no-cors"
      };
      window.dataLayer.push({ event: "review_document_button_pressed" });
      // Perform the upload using axios or fetch
      const res = await axios.post(
        process.env.REACT_APP_DOMAIN + "/api/v1/jobs/",
        formData,
        requestOptions
      );

      FetchDocuments(
        [searchedjobs, setSearchedJobs],
        localStorage.getItem("token")
      );
      localStorage.setItem("newJobCreatedDate", new Date());
      openModal();
    } catch (err) {
      document.getElementById("company-website").style.borderColor = "red";
      document.getElementById("company-website").classList.add("error");
      setError("Please insert a valid URL.");
      console.log(err.message);
      setUploading(false);
      notifications.push({
        icon: <AiOutlineCloseCircle size={25} color="red" />,
        upper_text: "Unable to review your document!",
        lower_text: err.message,
      });
      setNotification_component(() => <Notification />);
      // setNotification_component(null);
    }
  };

  const handleUploadClickComparison = async () => {
    setUploading(true);
    setError(null);
    try {
      // create FormData to send the file
      const formData = new FormData();

      formData.append("email_text", questions.join("\n"));
      formData.append("source", user.email);
      if (option === 1) formData.append("playbook_id", 148);
      else formData.append("playbook_id", -1);

      if (
        file &&
        file2 &&
        (file.name.includes(".docx") || file.name.includes(".pdf")) &&
        (file2.name.includes(".docx") || file2.name.includes(".pdf"))
      ) {
        formData.append("job_type", "compare");
        formData.append("original_file", file, file.name);
        formData.append("original_file_two", file2, file2.name);
      } else if (!file) {
        throw new Error("No file selected");
      } else if (!(file.name.includes(".docx") || file.name.includes(".pdf"))) {
        throw new Error(
          "File not supported, supported files are (.pdf, .docx)"
        );
      }

      if (optionalFieldsEnabled) {
        if (country && country !== "") {
          formData.append("country", country);
        }
        if (contractType && contractType !== "") {
          formData.append("contract_type", contractType);
        }
        if (partySide && partySide !== "") {
          formData.append("party_side", partySide);
        }
        if (purpose && purpose !== "") {
          formData.append("purpose", purpose);
        }
      }
      // formData.append('url', url);
      const requestOptions = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("token"),
        },
        // mode: "no-cors"
      };
      // Perform the upload using axios or fetch
      const res = await axios.post(
        process.env.REACT_APP_DOMAIN + "/api/v1/jobs/",
        formData,
        requestOptions
      );

      FetchDocuments(
        [searchedjobs, setSearchedJobs],
        localStorage.getItem("token")
      );
      localStorage.setItem("newJobCreatedDate", new Date());
      openModal();
      setUploading(false);
      setFile(null);
      setUploadText(
        <>
          Drag & drop or{" "}
          <span className="underline underline-offset-2">Select file</span>
        </>
      );
    } catch (err) {
      console.log(err.message);
      setUploading(false);
      notifications.push({
        icon: <AiOutlineCloseCircle size={25} color="red" />,
        upper_text: "Unable to review your document!",
        lower_text: err.message,
      });
      setNotification_component(() => <Notification />);
      // setNotification_component(null);
    }
  };
  document.addEventListener("click", function (e) {
    if (error && e.target.id !== "submit-url") {
      document.getElementById("company-website").classList.remove("error");
      document.getElementById("company-website").style.borderColor = "inherit";
      setError("");
    }
  });

  let tabsData = [
    {
      label: "Upload document",
      value: "file",
      icon: <CgSoftwareUpload size={20} />,
      button: (
        <button
          type="submit"
          className=" step4 text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer"
          onClick={handleUploadClickFile}
          disabled={uploading}
        >
          <HiOutlineDocumentText className="mr-2" size={20} />
          {uploading ? "Uploading..." : "Review document"}
        </button>
      ),
      content: (
        <>
          <div className="flex flex-col md:flex-row">
            <div>
              <form
                id="form-file-upload"
                onDragEnter={handleDrag}
                onDrop={handleDrop}
                onDragOver={handleDrag}
                onSubmit={(e) => e.preventDefault()}
                className="md:mr-12"
              >
                <label className="block mb-2 text-md font-[500] Inter">
                  Upload your document{" "}
                  <span className="text-sm text-slate-500">
                    (Supported files: docx, pdf, scanned pdf)
                  </span>
                </label>
                {/* <label htmlFor="file-upload" className="absolute max-w-[500px] font-bold"> */}
                <div className="ml-10 my-6 text-end max-w-[500px] border-2 border-dashed border-blue  cursor-pointer rounded-2xl bg-[#3056D3]/[.1] shadow-xl">
                  {file ? (
                    <button className="mr-3 mt-3" onClick={handleClearFile}>
                      <IoIosCloseCircleOutline color={"red"} size={25} />
                    </button>
                  ) : (
                    <></>
                  )}

                  <label
                    htmlFor="file-upload"
                    className={`step2 block cursor-pointer font-bold py-20 ${
                      file ? "pt-[0.32rem]" : "pt-12"
                    } pb-12`}
                  >
                    {file ? (
                      <></>
                    ) : (
                      <div className="icon-upload color ">
                        <CgSoftwareUpload
                          className="text-blue mb-8 mx-auto "
                          size={40}
                        />
                      </div>
                    )}
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="m-auto navbar-font font-normal cursor-pointer"
                      >
                        {upload_text}
                      </label>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                      />
                    </div>
                  </label>
                  {/* { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> } */}
                </div>
              </form>
              <CheckBoxComponent />
            </div>
            <div className="hidden md:block bg-[#748AF2] w-1 min-h-[100px] md:mr-8"></div>

            {/* InfoBox */}
            <div className="max-w-lg">
              <InfoBox />
            </div>
          </div>
        </>
      ),
    },
    {
      label: "Insert website URL",
      value: "link",
      icon: <AiOutlineLink size={20} />,
      button: (
        <button
          type="submit"
          id="submit-url"
          className="text-l bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer"
          onClick={handleUploadClickURL}
        >
          <HiOutlineDocumentText className="mr-2" size={18} />
          {uploading ? "Uploading..." : "Review document"}
        </button>
      ),
      content: (
        <div>
          <label className="block text-md font-[500] Inter mb-4">
            Insert the URL corresponding to the document
          </label>
          <div className="relative mt-1 rounded-md w-[637px] step2">
            <input
              type="text"
              placeholder="&#xf0c1;  https://www.example.com/"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onPaste={handleUrl}
              name="company-website"
              id="company-website"
              style={{ fontFamily: "Inter, FontAwesome", fontSize: "16px" }}
              className="w-[637px] border p-4 text-lg rounded focus:outline-none Inter"
            />
          </div>
          {error && (
            <p className="mt-2 flex text-red-primary items-center ml-4 text-xs Inter">
              <MdOutlineError className="mr-2" size={17} />
              {error}
            </p>
          )}
        </div>
      ),
    },
  ];

  if (useFeatureFlagEnabled("[FE]-[Upload]-compare_2_documents"))
    tabsData.push({
      label: "Compare 2 documents",
      value: "two_documents",
      icon: <CgSoftwareUpload size={20} />,
      button: (
        <button
          type="submit"
          id="submit-url"
          className="text-xl bg-[#748AF2] text-white shadow border-2 rounded-lg p-3 pr-5 pl-5 flex items-center hover:bg-[#748AF2] hover:bg-opacity-70 hover:text-white fade-in duration-300 cursor-pointer"
          onClick={handleUploadClickComparison}
        >
          <HiOutlineDocumentText className="mr-2" size={18} />
          {uploading ? "Uploading..." : "Review document"}
        </button>
      ),
      content: (
        <>
          <div className="flex mb-1">
            <label className="block text-md text-lg font-[500] Inter w-[500px] mr-8">
              Upload your document
            </label>
            <label className="block text-md text-lg  font-[500] Inter">
              Upload your document 2
            </label>
          </div>
          <div className="flex">
            <div className="w-[500px] mr-8 max-w-[500px] border-2 border-dashed border-blue mt-1 mb-6 cursor-pointer rounded-2xl bg-[#3056D3]/[.1] shadow-xl">
              <label
                htmlFor="file-upload"
                className="block cursor-pointer font-bold py-20 pt-12  pb-12"
              >
                <div className="icon-upload color ">
                  <CgSoftwareUpload
                    className="text-blue mb-8 mx-auto "
                    size={40}
                  />
                </div>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="m-auto navbar-font font-normal"
                  >
                    {upload_text}
                  </label>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleFileChange}
                  />
                </div>
              </label>
            </div>
            <div className="w-[500px] max-w-[500px] border-2 border-dashed border-blue mt-1 mb-6 cursor-pointer rounded-2xl bg-[#3056D3]/[.1] shadow-xl">
              <label
                htmlFor="file-upload2"
                className="block cursor-pointer font-bold py-20 pt-12  pb-12"
              >
                <div className="icon-upload color ">
                  <CgSoftwareUpload
                    className="text-blue mb-8 mx-auto "
                    size={40}
                  />
                </div>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="file-upload2"
                    className="m-auto navbar-font font-normal"
                  >
                    {upload_text2}
                  </label>
                  <input
                    id="file-upload2"
                    name="file-upload2"
                    type="file"
                    className="sr-only"
                    onChange={handleFileChange2}
                  />
                </div>
              </label>
            </div>
          </div>
        </>
      ),
    });

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // User balance fetch
  const [Userinfo, setUserinfo] = useState(0);

  useEffect(() => {
    FetchUserInfo().then((result) => {
      setUserinfo(result);
    });
  }, []);

  const steps = [
    {
      target: ".step1",
      content:
        "Upload the document you wish to review or provide an URL for a website to review",
    },
    {
      target: ".step2",
      content: "You can upload or select a file to review",
    },
    {
      target: ".step3",
      title: "User questions",
      content:
        "You can ask one or more questions you would like to know about your document",
      placement: "top",
    },
    {
      target: ".step4",
      content: "Submit your document for review (this may take some minutes)",
      placement: "top",
    },
  ];

  return (
    <Container>
      <Modal open={modal} closeModal={closeModal} />
      {useFeatureFlagEnabled("[FE]-[Global]-show_onboarding") && (
        <Onboarding steps={steps} />
      )}

      <div className="mt-16 m-10 mb-8">
        <div className="flex justify-between items-center">
          <div className="mb-10 font-black text-3xl Inter">Review Document</div>
          {notification_component}
        </div>
        <div
          className="flex border rounded max-w-fit shadow-md step1"
          data-intro="Hello step one!"
        >
          {tabsData.map((tab, idx) => {
            return (
              <button
                key={idx}
                className={`text-[#637381] navbar-font rounded flex py-2 p-8 m-1 transition-colors duration-300 items-center  ${
                  idx === activeTabIndex
                    ? "bg-[#F4F7FF]"
                    : " hover:border-gray-200"
                }`}
                // Change the active tab on click.
                onClick={() => {
                  setActiveTabIndex(idx);
                }}
              >
                <span className="mr-2 ">{tab.icon}</span>
                <span>{tab.label}</span>
              </button>
            );
          })}
        </div>
        <span className="absolute bottom-0 block h-1 bg-teal-500 transition-all duration-300" />
        <div className="my-10">
          <div>{tabsData[activeTabIndex].content}</div>
        </div>
        {optionalFieldsEnabled && (
          <OptinalFields
            setCountry={setCountry}
            setContractType={setContractType}
            setPartySide={setPartySide}
            setPurpose={setPurpose}
            country={country}
            contractType={contractType}
            partySide={partySide}
            purpose={purpose}
          />
        )}
        {useFeatureFlagEnabled("[FE]-[Upload]-dropdown_custom_options") &&
        tabsData[activeTabIndex].value !== "two_documents" ? (
          <div className="mb-10 w-1/3">
            <label className="block text-md text-[16px] font-[500] Inter mb-4">
              Question playbook
            </label>
            <Dropdown filters={playbook} dropDownChild={setDropDownChild} />
          </div>
        ) : (
          <></>
        )}

        {/* <Questions questions={questions} setQuestions={setQuestions} /> */}

        <div className="flex w-full mt-8 xl:w-[637px] items-center">
          {tabsData[activeTabIndex].button}
          <div
            className="Inter text-[#748AF2] px-4 py-3 text-[15px]"
            role="alert"
          >
            <span className="mb-8 Inter">
              <span className="font-bold">{Userinfo.remaining_balance}</span>{" "}
              {Userinfo.remaining_balance === 1 ? "credit" : "credits"} left
            </span>
          </div>
        </div>
        <Disclaimer styles={"shadow-none"} />
      </div>
    </Container>
  );
}

export default UploadContract;
