import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
// import posthog from "posthog-js";
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { PostHogProvider } from 'posthog-js/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import './lib/axios'
const root = ReactDOM.createRoot(document.getElementById('root')!);

const options = {
  api_host: 'https://eu.posthog.com',
};
if (process.env.REACT_APP_ASK_SENTRY_ACTIVATED === 'true') {
  Sentry.init({
    dsn: 'https://18e64637fc3f490a834c0c321b94d3fb@o4504156637167616.ingest.sentry.io/4504763018903552',
    integrations: [new Integrations.BrowserTracing() as any],
    tracesSampleRate: 1.0,
  });
}

root.render(
  <React.StrictMode>
    <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG as string} options={options}>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <App />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
