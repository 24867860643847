import React from "react";
import { COUNTRIES } from "../../helpers/countries";
import Select from "react-select";

interface IOptionalFieldsProps {
  country: string;
  setCountry: (value: string) => void;
  contractType: string;
  setContractType: (value: string) => void;
  partySide: string;
  setPartySide: (value: string) => void;
  purpose: string;
  setPurpose: (value: string) => void;
}

const OptionalFields: React.FC<IOptionalFieldsProps> = ({
  country,
  setCountry,
  contractType,
  setContractType,
  partySide,
  setPartySide,
  purpose,
  setPurpose,
}) => {
  const countryOptions = COUNTRIES.map((country) => ({
    value: country,
    label: country,
  }));
  return (
    <div className="mb-10 w-1/3 space-y-4">
      <div className="flex gap-16">
        <div className="!w-1/2">
          <label className="block text-sm  font-[500] Inter mb-4">
            Country
          </label>
          <Select
            options={countryOptions}
            placeholder="Select country..."
            isSearchable
            onChange={(selected) => setCountry(selected ? selected.value : "")}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "14px",
                fontFamily: 'Roboto, sans-serif',
                paddingLeft: "8px",
                width: "180px",
                "&:hover": { },
                "&::placeholder":{
                  color: 'red'
                },
                borderColor: state.isFocused
                  ? "border border-gray-200"
                  : "border border-gray-400",
              }),
            }}
          />
        </div>
        <div>
          <label className="block text-sm  font-[500] Inter mb-4">
            Contract type
          </label>
          <input
            placeholder="Enter contract type"
            style={{ fontFamily: "Inter, FontAwesome", fontSize: "14px" }}
            className=" border px-4 py-2 rounded focus:outline-none Inter"
            value={contractType}
            onChange={(e) => setContractType(e.target.value)}
          />
        </div>
      </div>
      <div className="flex gap-8">
        <div>
          <label className="block text-sm  font-[500] Inter mb-4">Party</label>
          <input
            placeholder="Which party are you"
            style={{ fontFamily: "Inter, FontAwesome", fontSize: "14px" }}
            className=" border px-4 py-2 rounded focus:outline-none Inter"
            value={partySide}
            onChange={(e) => setPartySide(e.target.value)}
          />
        </div>
        <div>
          <label className="block text-sm  font-[500] Inter mb-4">
            Purpose
          </label>
          <textarea
            rows={5}
            placeholder="What is the contract for/what do you want to be checked?"
            style={{ fontFamily: "Inter, FontAwesome", fontSize: "14px" }}
            className=" border px-4 py-2 rounded focus:outline-none Inter"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default OptionalFields;
